import { useState } from "react";
import { FaApple, FaAndroid, FaCalendarCheck } from "react-icons/fa";
import { motion } from "framer-motion";

const handleButtonClick = (buttonName) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'ctaWhatsapp',
    buttonDetails: buttonName,
  });
};

const MobileAppSection = () => {
  const [isHovered, setIsHovered] = useState(null);

  const features = [
    {
      id: 1,
      title: "Alta Performance",
      description: "Desempenho extremamente rápido com execução de código otimizada",
      image: "https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c"
    },
    {
      id: 2,
      title: "Cross Platform",
      description: "Experiência perfeita em dispositivos iOS e Android",
      image: "https://images.unsplash.com/photo-1526045612212-70caf35c14df"
    },
    {
      id: 3,
      title: "Design Moderno",
      description: "Interface de usuário elegante e intuitiva para maior usabilidade",
      image: "https://images.unsplash.com/photo-1551650975-87deedd944c3"
    }
  ];

  return (
    <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="flex justify-center space-x-8 mb-12"
          >
            <motion.div
              whileHover={{ scale: 1.1 }}
              className="text-white p-4 rounded-full bg-gradient-to-r from-[#783BFF] to-[#151342]">
              <FaApple className="w-12 h-12 bg-gradient-to-r from-[#783BFF] to-[#151342]"/>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.1 }}
              className="text-white p-4 rounded-full bg-gradient-to-r from-[#783BFF] to-[#151342]">
              <FaAndroid className="w-12 h-12 bg-gradient-to-r from-[#783BFF] to-[#151342]"/>
            </motion.div>
          </motion.div>

          <h2 className="text-4xl font-bold text-gray-900 mb-4">Aplicativos de alto desempenho</h2>
          <p className="text-gray-600 text-lg mb-12">Experimente a próxima geração de computação mobile</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          {features.map((feature) => (
            <motion.div
              key={feature.id}
              onHoverStart={() => setIsHovered(feature.id)}
              onHoverEnd={() => setIsHovered(null)}
              whileHover={{ scale: 1.05 }}
              className="bg-gray-50 rounded-xl overflow-hidden shadow-xl"
            >
              <div className="relative h-48">
                <img
                  src={feature.image}
                  alt={feature.title}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            </motion.div>
          ))}
        </div>

        <div className="text-center">
      <a
        href="/forms" 
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block"
        onClick={() => handleButtonClick("Fale com a gente")}
        aria-label="Link para o whatsapp da biancode"
      >
        <motion.button
          className={`bg-gradient-to-r from-[#783BFF] to-[#151342] text-white px-8 py-3 rounded-full font-semibold text-lg flex items-center justify-center transition-all duration-300 ${
            isHovered ? "hover:bg-[#151342]" : ""
          }`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          whileHover={{ scale: 1.05 }} // Aumenta o botão ligeiramente no hover
          whileTap={{ scale: 0.95 }}
        >
          Fale com a gente
          <FaCalendarCheck className={`ml-2 ${isHovered ? "animate-pulse" : ""}`} />
        </motion.button>
      </a>
    </div>
      </div>
    </div>
  );
};

export default MobileAppSection;