import React from "react";
import { FaFutbol } from "react-icons/fa";
import academyBackground from '../assets/images/academy.png'; 

const Academy = ({ id }) => {
  return (
    <div id={id} className="relative h-screen flex items-center justify-center overflow-hidden">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${academyBackground})` }} // Aplicando a imagem corretamente
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>

      <div className="relative z-10 text-center px-4">
        <h1 className="text-4xl md:text-6xl font-bold mb-4 text-white" style={{ fontFamily: 'ZenDots' }}>
          Treinamos nossos próprios <span className="text-5xl md:text-7xl text-blue-400">DEVS</span>
        </h1>
        <p className="text-xl md:text-2xl text-white mb-8 max-w-3xl mx-auto">
        Treinamos Desenvolvedores para Excelência em Aplicativos Mobile e Web com as melhores práticas e metodologias ágeis, preparando-os para integrar
          <span className="text-blue-400"> nosso time principal:</span> a nossa 
          <span className="text-blue-400"> Software House.</span>
        </p>
      </div>

      <div className="absolute bottom-0 left-0 w-full h-16 bg-gradient-to-t from-blue-800 to-transparent"></div>

      {/* Ajuste para as duas bolas de futebol com media queries */}
      <FaFutbol className="absolute text-white text-6xl md:text-6xl sm:text-2xl animate-bounce" style={{ bottom: "5%", left: "10%" }} />
      <FaFutbol className="absolute text-white text-6xl md:text-6xl sm:text-2xl animate-bounce" style={{ bottom: "5%", right: "10%" }} />
    </div>
  );
};

export default Academy;
