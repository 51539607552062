import React, { useState, useEffect } from "react";
import { FaLinkedin, FaGlobe } from "react-icons/fa";
import joao from '../assets/images/joao_team.png';
import linquinho from '../assets/images/linquinho_team.png';
import nat from '../assets/images/nat_team.png';
import helio from '../assets/images/helio_team.png';
import lincoln from '../assets/images/lincoln_team.png';
import rafinha from '../assets/images/rafinha_team.png';

const EmployeeCarousel = ({ id }) => {
  const employees = [
    {
      id: 1,
      name: "Lincoln Matos",
      position: "CEO & Fundador",
      image: lincoln,
      linkedin: "https://www.linkedin.com/in/lincoln-biancardi-9429622a/",
      portfolio: "https://github.com/lincolnbiancard"
    },
    {
      id: 2,
      name: "Hélio Biancardi",
      position: "CTO & Fundador",
      image: helio,
      linkedin: "https://www.linkedin.com/in/helio-biancardi/",
      portfolio: "https://github.com/lincolnbiancard"
    },
    {
      id: 3,
      name: "João Victor",
      position: "Especialista em Frontend",
      image: joao,
      linkedin: "https://www.linkedin.com/in/joao-biancardi/",
      portfolio: "https://github.com/lincolnbiancard"
    },
    {
      id: 4,
      name: "Natália Muzzi",
      position: "Marketing e Vendas",
      image: nat,
      linkedin: "https://www.linkedin.com/in/natalia-muzzi/",
      portfolio: "https://github.com/lincolnbiancard"
    },
    {
      id: 5,
      name: "Lincoln Meirelles",
      position: "Full Stack Developer",
      image: linquinho,
      linkedin: "https://www.linkedin.com/in/lincoln-meirelles-48005a192/",
      portfolio: "https://github.com/lincolnbiancard"
    },
    {
      id: 6,
      name: "Rafael do Nascimento",
      position: "Full Stack Developer",
      image: rafinha,
      linkedin: "https://www.linkedin.com/in/rafaeldnm",
      portfolio: "https://github.com/lincolnbiancard"
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.innerWidth < 640) { // Verifica se é mobile
        setCurrentIndex((prevIndex) =>
          prevIndex === employees.length - 1 ? 0 : prevIndex + 1
        );
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [employees.length]);

  const handlePrev = () => {
    if (window.innerWidth >= 640) {
      // No desktop, retrocede até o início e depois para o final, se estiver na primeira posição
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? Math.floor(employees.length / 6) - 1 : prevIndex - 1
      );
    } else {
      // No mobile, mantém a rotação normal do carrossel
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? employees.length - 1 : prevIndex - 1
      );
    }
  };

  const handleNext = () => {
    if (window.innerWidth >= 640) {
      setCurrentIndex(0); // No desktop, reinicia sempre para a primeira posição
    } else {
      setCurrentIndex((prevIndex) =>
        prevIndex === employees.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  return (
    <div id={id} className="bg-white flex items-center justify-center py-6">
      <div className="max-w-7xl w-full px-4 py-4">
        <h2 className="text-4xl font-bold text-center mb-6 text-gray-800" style={{ fontFamily: 'ZenDots' }}>
          Nosso Time
        </h2>
        <div className="relative overflow-hidden">
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(-${window.innerWidth < 640 ? currentIndex * 100 : currentIndex * (100 / employees.length)}%)`,
            }}

          >
            {employees.map((employee) => (
              <div key={employee.id} className="w-full sm:w-1/6 px-4 flex-shrink-0 flex flex-col items-center">

                <div className="w-64 h-64 mb-4 overflow-hidden transition-transform duration-300 hover:scale-105">
                  <img
                    src={employee.image}
                    alt={employee.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  {employee.name}
                </h3>
                <p className="text-md text-gray-600 mb-2">{employee.position}</p>
                <div className="flex space-x-4">
                  <a
                    href={employee.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800 transition-colors duration-300"
                    aria-label={`Perfil do LinkedIn de ${employee.name}`}
                  >
                    <FaLinkedin size={24} />
                  </a>
                  <a
                    href={employee.portfolio}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-gray-800 transition-colors duration-300"
                    aria-label={`Perfil do portfolio de ${employee.name}`}
                  >
                    <FaGlobe size={24} />
                  </a>
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={handlePrev}
            className="absolute top-1/2 left-1 transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-all duration-300"
            aria-label="Anterior"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 right-1 transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-all duration-300"
            aria-label="Seguinte"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCarousel;
