import React, { useState, useEffect } from "react";
import { FaComments } from "react-icons/fa";
import backgroundImage from '../assets/images/cta2.png';

const handleButtonClick = (buttonName) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'ctaWhatsapp',
    buttonDetails: buttonName,
  });
};

const CtaSection = ({ id }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [particles, setParticles] = useState([]);

  useEffect(() => {
    const generateParticles = () => {
      const newParticles = [];
      for (let i = 0; i < 50; i++) {
        newParticles.push({
          id: i,
          x: Math.random() * 100,
          y: Math.random() * 100,
          size: Math.random() * 3 + 1,
          speed: Math.random() * 0.5 + 0.1,
        });
      }
      setParticles(newParticles);
    };

    generateParticles();
    const interval = setInterval(generateParticles, 5000);

    return () => clearInterval(interval);
  }, []);

  const moveParticles = () => {
    setParticles((prevParticles) =>
      prevParticles.map((particle) => ({
        ...particle,
        x: (particle.x + particle.speed) % 100,
        y: (particle.y + particle.speed) % 100,
      }))
    );
  };

  useEffect(() => {
    const animationFrame = requestAnimationFrame(moveParticles);
    return () => cancelAnimationFrame(animationFrame);
  }, [particles]);

  return (
    <div id={id} className="min-h-screen flex flex-col lg:flex-row items-center justify-center p-8 relative overflow-hidden bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900">
      {particles.map((particle) => (
        <div
          key={particle.id}
          className="absolute bg-white opacity-50 rounded-full"
          style={{
            left: `${particle.x}%`,
            top: `${particle.y}%`,
            width: `${particle.size}px`,
            height: `${particle.size}px`,
          }}
        />
      ))}
      <div className="w-full lg:w-1/2 z-10 mb-8 lg:mb-0">
        <h1 className="text-4xl md:text-6xl font-bold mb-6 text-white drop-shadow-lg" style={{ fontFamily: 'ZenDots' }}>
          Seu Aplicativo Mobile ou Sistema Pronto em Até 60 Dias!
        </h1>
        <p className="text-xl md:text-2xl mb-8 text-blue-200">
          Acelere seu projeto e fique à frente da concorrência! Transformamos suas ideias em soluções robustas, ágeis e escaláveis ​​em tempo recorde.
        </p>
        <a
          href="/forms" 
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block"
          aria-label="Link para o whatsapp da biancode"
        >
          <button
            className="bg-[#FF9200] text-blue-900 font-bold py-3 px-8 rounded-full text-xl flex items-center justify-center transition-all duration-300 hover:bg-[#1FC48A] hover:scale-105"
            onClick={() => handleButtonClick("Vamos conversar")}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            aria-label="Botão de Vamos conversar"
          >
            Vamos conversar?
            <FaComments className={`ml-2 ${isHovered ? "animate-pulse" : ""}`} />
          </button>
        </a>
      </div>

      <div className="w-full lg:w-1/2 relative">
        <img
          src={backgroundImage}
          alt="White robotic hand with transparent background"
          className="w-full h-auto rounded-lg"
        />
        {isHovered && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-lg">
            <div className="flex space-x-8">
              {["Briefing", "Prototipagem", "Desenvolvimento", "Testes", "Projeto Rodando"].map((stage, index) => (
                <div key={index} className="flex flex-col items-center">
                  <div className="w-16 h-16 bg-[#1FC48A] rounded-full flex items-center justify-center mb-2">
                    <span className="text-blue-900 font-bold">{index + 1}</span>
                  </div>
                  <span className="text-white">{stage}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CtaSection;
