import React, { useState } from "react";
import { FaShieldAlt, FaDollarSign, FaArrowRight, FaArrowLeft, FaWhatsapp, FaCheckCircle, FaUsers, FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import logo from '../assets/images/logo_roxo.png';
import { db } from '../infra/firebase';
import { updateDoc, doc, setDoc } from "firebase/firestore";
import { formatInTimeZone } from "date-fns-tz";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';


const RegistrationScreen = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    companyName: "",
    projectStage: "",
    alreadyHiredTech: "",
    projectObjective: "",
    team: "",
    budget: "",
    referenceNumber: ""
  });

  const handleButtonClick = (buttonName) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'conversion_event_purchase ',
      buttonDetails: buttonName,
    });

    if (window.gtag) {
      window.gtag('event', 'conversion_event_purchase', {
        // Parâmetros do evento, se aplicável
      });
    }
  };

  const sanitizePhoneNumber = (phone) => {
    return phone.replace(/[^0-9]/g, ""); // Remove tudo que não é número
  };

  const isValidEmail = (email) => {
    // Esse regex exige que haja:
    // - algum caracter antes do '@'
    // - um domínio com pelo menos um ponto
    // - e um TLD com pelo menos 2 caracteres
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  const handleEmailBlur = () => {
    if (formData.email && !isValidEmail(formData.email)) {
      toast.error("Por favor, insira um email válido.");
    }
  };

  const [tempDocId, setTempDocId] = useState(null);

  const preSaveData = async () => {
    try {
      // Define o timestamp no fuso horário UTC-3 (Horário de Brasília)
      const timeZone = "America/Sao_Paulo"; // Define o fuso horário do Brasil (UTC-3)
      const currentTimestamp = formatInTimeZone(new Date(), timeZone, "yyyy-MM-dd'T'HH:mm:ssXXX");

      setFormData((prev) => ({
        ...prev,
        timestamp: currentTimestamp
      }));

      // Use o e-mail como ID do documento ao salvar no Firebase
      const emailId = formData.email;
      await setDoc(doc(db, "preRegistrations", emailId), {
        fullName: formData.fullName,
        email: formData.email,
        phone: formData.phone,
        companyName: formData.companyName,
        timestamp: currentTimestamp // Inclui a data e hora no Firebase
      });

      setTempDocId(emailId); // Armazena o ID do documento como o e-mail
      console.log("Pré-salvamento concluído com ID:", emailId);
    } catch (e) {
      console.error("Erro ao pré-salvar dados:", e);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prev) => ({
      ...prev,
      [name]: name === "phone" ? sanitizePhoneNumber(value) : (type === "checkbox" ? checked : value)
    }));
  };

  const handleSelectionChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Adiciona a função gtagSendEvent aqui
  //  const gtagSendEvent = (url) => {
  //   const callback = function () {
  //     if (typeof url === 'string') {
  //       window.location = url;
  //     }
  //   };
  //   if (window.gtag) {
  //     window.gtag('event', 'conversion_event_purchase', {
  //       'event_callback': callback,
  //       'event_timeout': 2000,
  //     });
  //   }
  //   return false;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Verifica se estamos no primeiro step.
    if (currentStep === 1) {
      if (!isValidEmail(formData.email)) {
        toast.error("Por favor, insira um email válido.");
        return;
      }
      // Pré-salva os dados
      await preSaveData();
      
      // Dispara o evento do GTM apenas no primeiro step
      handleButtonClick('Forms');
      // Avança para o próximo step
      setCurrentStep(currentStep + 1);
    } else if (currentStep < 6) {
      // Apenas avança os steps sem disparar o evento novamente
      setCurrentStep(currentStep + 1);
    } else {
      if (tempDocId) {
        const referenceNumber = "BIAN" + Math.random().toString(36).substr(2, 9).toUpperCase();
        // Atualize o formData com o número de referência
        setFormData((prev) => ({
          ...prev,
          referenceNumber: referenceNumber
        }));
  
        // Salve o formData final, incluindo o número de referência, no Firebase
        const docRef = doc(db, "preRegistrations", tempDocId);
        await updateDoc(docRef, { ...formData, referenceNumber });
  
        setCurrentStep(7); // Avança para o Step 7 após salvar
      }
    }
  };  

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const projectStages = [
    { id: "Inicial", label: "Ideia Inicial", description: "Apenas conceitos ou ideias, sem desenvolvimento iniciado" },
    { id: "Definido", label: "Especificações Definidas", description: "Projeto bem estruturado com requisitos claros" },
    { id: "MVP", label: "Protótipo ou MVP", description: "Possui um protótipo ou MVP em desenvolvimento ou concluído" },
    { id: "Em_Progresso", label: "Em Progresso", description: "Desenvolvimento iniciado e requer continuidade" },
    { id: "Projeto_Em_Produção", label: "Projeto em Produção", description: "Projeto já lançado, mas precisa de manutenção" }
  ];

  const alreadyHiredTech = [
    { id: "yes", label: "Sim", icon: <FaThumbsUp className="text-2xl" /> },
    { id: "no", label: "Não", icon: <FaThumbsDown className="text-2xl" /> },
  ];

  const team = [
    "Até 5 pessoas",
    "Entre 5 e 10 pessoas",
    "Entre 10 e 20 pessoas",
    "Entre 20 e 100 pessoas",
    "Mais de 100 pessoas"
  ];

  const budgets = [
    "Até R$ 30 mil",
    "Entre R$ 30 mil e R$50 mil",
    "Entre R$ 50 mil e R$100 mil",
    "Acima de R$ 100 mil"
  ];

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-6">
            <ToastContainer />
            <div>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                placeholder="Seu Nome"
                className="w-full px-4 py-3 rounded-lg bg-[#151342] text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#783BFF]"
                required
              />
            </div>

            <div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={handleEmailBlur}
                placeholder="Seu melhor Email"
                className="w-full px-4 py-3 rounded-lg bg-[#151342] text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#783BFF]"
                required
              />
            </div>

            <div className="flex">
              <div className="flex items-center bg-[#151342] rounded-l-lg px-3">
                <FaWhatsapp className="text-green-500 mr-2" />
                <span className="text-white">+55</span>
              </div>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Celular (WhatsApp)"
                className="flex-1 px-4 py-3 rounded-r-lg bg-[#151342] text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#783BFF]"
                required
              />
            </div>

            <div>
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                placeholder="Nome da Empresa ou Projeto"
                className="w-full px-4 py-3 rounded-lg bg-[#151342] text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#783BFF]"
                required
              />
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-4">
            <h3 className="text-xl text-white mb-4">Qual é o estágio atual do seu projeto?</h3>
            <div className="grid gap-4">
              {projectStages.map((stage) => (
                <button
                  key={stage.id}
                  onClick={(e) => { e.preventDefault(); handleSelectionChange("projectStage", stage.id) }}
                  className={`p-4 rounded-lg text-left ${formData.projectStage === stage.id ? "bg-[#783BFF] text-black" : "bg-[#151342] text-white"} hover:bg-[#783BFF] hover:text-black transition-colors`}
                >
                  <h4 className="font-semibold">{stage.label}</h4>
                  <p className="text-sm opacity-75">{stage.description}</p>
                </button>
              ))}
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-4">
            <h3 className="text-xl text-white mb-4">Já contratou tecnologia alguma outra vez?</h3>
            <div className="grid gap-4">
              {alreadyHiredTech.map((type) => (
                <button
                  key={type.id}
                  onClick={(e) => { e.preventDefault(); handleSelectionChange("alreadyHiredTech", type.id) }}
                  className={`p-4 rounded-lg flex items-center ${formData.alreadyHiredTech === type.id ? "bg-[#783BFF] text-black" : "bg-[#151342] text-white"} hover:bg-[#783BFF] hover:text-black transition-colors`}
                >
                  <div className="mr-4">{type.icon}</div>
                  <span>{type.label}</span>
                </button>
              ))}
            </div>
          </div>
        );

      case 4:
        return (
          <div className="space-y-6">
            <div>
              <h3 className="text-xl text-white mb-4">Você pode nos explicar com mais detalhes o seu projeto?:</h3>
              <textarea
                name="projectObjective"
                value={formData.projectObjective}
                onChange={handleInputChange}
                placeholder="Objetivo do Projeto"
                className="w-full px-4 py-3 rounded-lg bg-[#151342] text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#783BFF] min-h-[100px]"
                required
              />
            </div>

          </div>
        );

      case 5:
        return (
          <div className="space-y-4">
            <h3 className="text-xl text-white mb-4">Qual é o tamanho da equipe na sua organização?</h3>
            <div className="grid gap-4">
              {team.map((time) => (
                <button
                  key={time}
                  onClick={(e) => { e.preventDefault(); handleSelectionChange("team", time) }}
                  className={`p-4 rounded-lg flex items-center ${formData.team === time ? "bg-[#783BFF] text-black" : "bg-[#151342] text-white"} hover:bg-[#783BFF] hover:text-black transition-colors`}
                >
                  <FaUsers className="mr-4" />
                  <span>{time}</span>
                </button>
              ))}
            </div>
          </div>
        );

      case 6:
        return (
          <div className="space-y-4">
            <h3 className="text-xl text-white mb-4">Qual seu orçamento pra esse projeto?</h3>
            <div className="grid gap-4">
              {budgets.map((budget) => (
                <button
                  key={budget}
                  onClick={(e) => { e.preventDefault(); handleSelectionChange("budget", budget) }}
                  className={`p-4 rounded-lg flex items-center ${formData.budget === budget ? "bg-[#783BFF] text-black" : "bg-[#151342] text-white"} hover:bg-[#783BFF] hover:text-black transition-colors`}
                >
                  <FaDollarSign className="mr-4" />
                  <span>{budget}</span>
                </button>
              ))}
            </div>
          </div>
        );

      case 7:
        return (
          <div className="text-center space-y-6">
            <FaCheckCircle className="text-[#783BFF] text-6xl mx-auto animate-bounce" />
            <h3 className="text-2xl text-white font-bold">Estamos empolgados por colaborar com você nesse projeto!</h3>
            <p className="text-white text-lg">Revisaremos suas informações e entraremos em contato em breve.</p>
            <div className="p-4 bg-[#151342] rounded-lg text-white">
              <p>Sua solicitação foi enviada com sucesso.</p>
              <p className="text-sm opacity-75 mt-2">Número de referência: {formData.referenceNumber}</p>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-black py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto">
        <div className="text-center mb-4 relative">
          <img
            src={logo}
            alt="Logo"
            className="mx-auto lg:w-48"
          />
          {currentStep === 1 && (
            <p className="text-white text-md top-3/4 left-1/2">
              Queremos entender seu projeto antes de nossa primeira conversa!
              Ao preencher este formulário, você nos ajuda a conhecer as principais ideias e necessidades da sua solução. Assim, quando nos encontrarmos, já teremos o contexto ideal para guiarmos a reunião da melhor forma possível e focarmos no que realmente importa para você e sua empresa.
            </p>
          )}
        </div>

        <div className="flex justify-center space-x-2 mb-8">
          {[1, 2, 3, 4, 5, 6].map((step) => (
            <div
              key={step}
              className={`w-16 h-2 rounded-full ${step <= currentStep ? "bg-[#783BFF]" : "bg-[#151342]"}`}
            ></div>
          ))}
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {renderStepContent()}

          <div className="flex justify-between space-x-4">
            {currentStep > 1 && currentStep < 7 && (
              <button
                type="button"
                onClick={handleBack}
                className="flex-1 py-3 px-4 rounded-lg bg-[#151342] text-white font-semibold hover:bg-[#232156] transition duration-300"
              >
                <FaArrowLeft className="inline mr-2" /> Voltar
              </button>
            )}
            {currentStep < 7 && (
              <button
                type="submit"
                className="flex-1 py-3 px-4 rounded-lg bg-[#783BFF] text-black font-semibold hover:bg-[#6232cc] transition duration-300"
              >
                {currentStep === 6 ? "Enviar" : "Continuar"} <FaArrowRight className="inline ml-2" />
              </button>
            )}
          </div>

          <div className="flex items-center justify-center text-white space-x-2 mt-4">
            <FaShieldAlt className="text-xl" />
            <span>Ambiente 100% Seguro</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationScreen;
