import React, { useState } from "react";
import { FaClipboardList, FaPencilAlt, FaCode, FaBug, FaRocket, FaThumbsUp } from "react-icons/fa";

const StagesComponent = ({ id }) => {
  const [currentStage, setCurrentStage] = useState(0);

  const stages = [
    { icon: FaClipboardList, title: "Briefing", description: "Nesta fase inicial, discutimos detalhadamente suas necessidades, objetivos e restrições do projeto. Coletamos todas as informações essenciais para garantir uma base sólida para o desenvolvimento." },
    { icon: FaPencilAlt, title: "Prototipagem", description: "Criamos um protótipo visual inicial do sistema, permitindo validar conceitos e ajustar funcionalidades com base no seu feedback antes de iniciar o desenvolvimento completo." },
    { icon: FaCode, title: "Desenvolvimento", description: "Codificamos e implementamos funcionalidades específicas para o desenvolvimento de aplicativos móveis e web. Garantindo integração eficaz entre os componentes e mantendo uma colaboração contínua com você." },
    { icon: FaBug, title: "QA (Garantia de Qualidade)", description: "Realizamos testes rigorosos para identificar e corrigir bugs, além de avaliar o desempenho geral do sistema, garantindo que o produto atenda aos padrões de qualidade exigidos." },
    { icon: FaRocket, title: "Deploy", description: "Após a aprovação da QA, lançamos o sistema em produção, monitorando de perto o desempenho inicial e garantindo uma transição suave para o ambiente de uso real." },
    { icon: FaThumbsUp, title: "Aguardando Sua Aprovação", description: "Nesta fase final, implementamos os ajustes necessários e aguardamos sua aprovação final para concluir o projeto e garantir que tudo esteja de acordo com suas expectativas." }
  ];

  const handleStageClick = (index) => {
    setCurrentStage(index);
  };

  const handleNextStage = () => {
    if (currentStage < stages.length - 1) {
      setCurrentStage(currentStage + 1);
    }
  };

  return (
    <div id={id} className="container mx-auto px-4 py-8">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-800 mb-2" style={{ fontFamily: 'ZenDots' }}>Etapas do Projeto</h1>
        <p className="text-xl text-gray-600">Acompanhe o progresso do seu projeto através destas etapas</p>
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center md:items-start mb-8 space-y-8 md:space-y-0 md:space-x-8">
        {stages.map((stage, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-center md:items-center mb-4 md:mb-0 relative"
          >
            <div
              className={`w-16 h-16 rounded-full flex items-center justify-center shadow-md transition-all duration-300 cursor-pointer ${
                index <= currentStage
                  ? "bg-gradient-to-r from-[#783BFF] to-[#151342]"
                  : "bg-gray-200"
              }`}
              onClick={() => handleStageClick(index)}
              tabIndex="0"
              role="button"
              aria-label={`Stage ${index + 1}: ${stage.title}`}
            >
              <stage.icon
                className={`text-2xl ${
                  index <= currentStage ? "text-white" : "text-gray-500"
                }`}
              />
            </div>
            <h2 className="mt-4 font-bold text-center text-gray-800">{stage.title}</h2>
            <p className="text-sm text-center text-gray-600 mt-2 max-w-xs">
              {stage.description}
            </p>
            {index < stages.length - 1 && (
              <div className="hidden md:block absolute top-8 left-full w-full border-t-2 border-dotted border-gray-300 -z-10" />
            )}
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        <button
          className={`px-6 py-2 rounded-full font-bold text-white shadow-lg transition-all duration-300 ${
            currentStage === stages.length - 1
              ? "bg-[#151342] hover:bg-[#783BFF]"
              : "bg-[#783BFF] hover:bg-[#151342]"
          }`}
          onClick={handleNextStage}
          disabled={currentStage === stages.length - 1}
          aria-label="Proximo Passo"
        >
          {currentStage === stages.length - 1 ? "Projeto Entregue" : "Próxima Etapa"}
        </button>
      </div>
    </div>
  );
};

export default StagesComponent;
