// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Importa o Firestore
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCN2Lgp7OBQM8kxuSMlpMvf4mvWJoZL_Jw",
  authDomain: "biancode-f976d.firebaseapp.com",
  projectId: "biancode-f976d",
  storageBucket: "biancode-f976d.firebasestorage.app",
  messagingSenderId: "764409784098",
  appId: "1:764409784098:web:46da2ad6b33cc40cf9277b",
  measurementId: "G-SS60SLPMCE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app); // Inicializa o Firestore e exporta

export { db, analytics }; // Exporta o Firestore para ser usado nos componentes
